import React, { useState } from 'react';
import Header from '../header/Header';

function ContactMainPage() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    comment: '',
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form Submitted:', formData);
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      comment: '',
    });
  };

  return (
    <div>
      <Header />
      <div className='bg-banner2 2xl:h-[370px] xl:h-[320px] flex justify-center items-center'>
        <h1 className='adderley text-white md:text-[80px] text-[50px] font-bold'>Contact Us</h1>
      </div>

      {/* <form className="max-w-1440 lg:w-[100%] mx-auto xl:px-[8vh] px-[3vh] md:my-[10vh] py-[10vh]" onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 mb-6">
            <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="firstName">
              Enter your First Name
            </label>
            <input className="appearance-none block w-full bg-[#1D1C1C] text-gray-700 lg:h-[60px] h-[50px] px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="firstName" name="firstName" type="text" value={formData.firstName} onChange={handleChange} required />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6">
            <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="lastName">
              Enter Your Last Name
            </label>
            <input className="appearance-none block w-full bg-[#1D1C1C] text-gray-700 lg:h-[60px] h-[50px] px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="lastName" name="lastName" type="text" value={formData.lastName} onChange={handleChange} required />
          </div>
        </div>
        <div className="w-full px-3 mb-6">
          <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="email">
            Enter Your Email
          </label>
          <input className="appearance-none block w-full bg-[#1D1C1C] text-gray-700 lg:h-[60px] h-[50px] px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" name="email" type="text" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="w-full px-3 mb-6">
          <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="phone">
            Enter Your Phone Number
          </label>
          <input className="appearance-none block w-full bg-[#1D1C1C] text-gray-700 lg:h-[60px] h-[50px] px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="phone" name="phone" type="text" value={formData.phone} onChange={handleChange} required />
        </div>
        <div className="w-full px-3 mb-6">
          <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="comment">
            Comment or Message
          </label>
          <textarea className="appearance-none block w-full bg-[#1D1C1C] text-gray-700 md:h-[200px] h-[150px] md:py-4 py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="comment" name="comment" value={formData.comment} onChange={handleChange} required></textarea>
        </div>
        <div>
          <button className='bg-[#4FF8A7] md:w-[179.66px] w-[130px] lg:h-[57px] h-[40px] font-thin lg:text-[20px] md:text-[14px] text-[12px]'>Send</button>
        </div>
      </form> */}
         <form className="max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[2vh] md:my-[10vh] py-[10vh]" onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2">
                        <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="firstName">
                            Enter your  First Name
                        </label>
                        <input className="appearance-none block w-full bg-[#1D1C1C]  text-gray-700 lg:h-[60px] h-[50px]   px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="firstName" name="firstName" type="text" value={formData.firstName} onChange={handleChange} required />
                    </div>
                    <div className="w-full px-3 mb-6 md:w-1/2">
                        <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="lastName">
                            Enter Your  Last Name
                        </label>
                        <input className="appearance-none block w-full bg-[#1D1C1C]  text-gray-700 lg:h-[60px] h-[50px]   px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="lastName" name="lastName" type="text" value={formData.lastName} onChange={handleChange} required />
                    </div>
                </div>
                <div className="w-full px-3 mb-6 md:w-[100%] -mx-3">
                    <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="email">
                        Enter Your  Email
                    </label>
                    <input className="appearance-none block xl:w-[102%] lg:w-[103%] md:w-[104%] w-[108%] bg-[#1D1C1C]  text-gray-700 lg:h-[60px] h-[50px]   px-3 mb-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" name="email" type="text" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="w-full px-3 mb-6 md:w-[100%] -mx-3">
                    <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="email">
                        Enter Your  Phone Number
                    </label>
                    <input className="appearance-none block xl:w-[102%] lg:w-[103%] md:w-[104%] w-[108%] bg-[#1D1C1C]  text-gray-700 lg:h-[60px] h-[50px]   px-3 mb-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="phone" name="phone" type="text" value={formData.phone} onChange={handleChange} required />
                </div>
                <div className="w-full px-3 mb-6 md:w-[100%] -mx-3">
                    <label className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2" htmlFor="comment">
                        Comment Or Message
                    </label>
                    <textarea className="appearance-none block xl:w-[102%] lg:w-[103%] md:w-[104%] w-[108%] bg-[#1D1C1C]  text-gray-700  md:h-[200px] h-[150px] md:py-4 py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white" id="comment" name="comment" type="text" value={formData.comment} onChange={handleChange} required></textarea>
                </div>
                <div>
                    <button className=' bg-[#4FF8A7] md:w-[179.66px] w-[130px] lg:h-[57px] h-[40px] font-thin lg:text-[20px] md:text-[14px] text-[12px]'>Send</button>
                </div>
            </form>
    </div>
  );
}

export default ContactMainPage;
