import React from 'react';

function Details() {
    return (
        <div className="lg:grid lg:grid-cols-11 gap-4  py-12  max-w-1440 w-[90%] mx-auto 2xl:max-w-[80%] 2xl:w-[85%] grid grid-cols-1">
            <div className="col-span-1 hidden lg:block"></div>
            <div className="col-span-3">
                <h1 className='text-white lg:text-[28px] text-[22px] font-bold text-center'>Location</h1>
                <p className='text-white text-center lg:text-[20px] text-[16px] font-normal'>Bodakdev,<br /> Ahmedabad,<br />Gujarat - 380054</p>
            </div>
            <div className="col-span-3 lg:mt-0 mt-4">
                <h1 className='text-white lg:text-[28px] text-[22px] font-bold text-center'>Hours of Operation</h1>
                <p className='text-white text-center lg:text-[20px] text-[16px] font-normal'>We are open <br />MONDAY To SUNDAY <br />12:30pm - 07:30pm</p>
            </div>
            <div className="col-span-3 lg:mt-0 mt-4">
                <h1 className='text-white lg:text-[28px] text-[22px] font-bold text-center'>Contact</h1>
                <p className='text-white text-center lg:text-[20px] text-[16px]' style={{ fontWeight: 400 }}>+91 9974533366</p>
            </div>
            <div className="col-span-1 hidden lg:block"></div>
        </div>
    );
}

export default Details;
