import React from 'react';
import OURARTIST from '../../assets/images/home/meet_our_Artist.png';
import { useNavigate } from 'react-router-dom';

function OurArtist() {
  const navigate = useNavigate();
  return (
    <div className='mt-[4vh] mb-[5vh]'>
      <div className='border-t-[1px] border-[#FFFFFF]'></div>
      <h1 className='adderley text-white text-[20px] md:text-[50px] font-bold mt-5 pt-[10px] text-center md:text-left'>
        MEET OUR ARTIST
      </h1>
      <div className='flex flex-col lg:flex-row lg:justify-between gap-9 mt-6'>
        <div className='flex flex-col items-center lg:items-start lg:w-1/3'>
          <img src={OURARTIST} alt='OURARTIST' className='w-full h-auto ' />
          {/* max-w-[358.67px] max-h-[438.79px] */}
        </div>
        <div className='flex flex-col justify-center lg:w-2/3'>
          <div className='2xl:leading-9 2xl:mt-0 xl:mt-0'>
            <h1 className='adderley text-white text-[30px] md:text-[50px] font-bold lg:mt-0 mt-4'>
              KRUNAL GOSWAMI
            </h1>
            <p className='text-[#4FF8A7] text-[20px] font-normal'>
              Professional Tattoo Artist | Experience 12 Years
            </p>
          </div>
          <p className='text-white font-normal text-justify text-[20px] xl:text-[19.70px] lg:text-[15px] xl:mt-6 mt-4'>
            Introducing Krunal Goswami, a talented tattoo artist with 10 years of experience. krunal Goswami is known for creating unique and meaningful tattoos, blending technical skills with artistic talent. Specializing in various tattoo styles, Krunal Goswami uses high-quality materials to ensure a safe and satisfying experience. With a keen eye for detail and a passion for art, Krunal Goswami transforms clients' ideas into beautiful body art, making each tattoo a true reflection of personal stories and individuality. Trust Krunal Goswami to bring your vision to life with exceptional artistry.
          </p>
          <div className='button mt-6 lg:mt-6'>
            <button
              className='font-medium text-white text-[13px] md:w-[362.62px] w-full py-[8px] border cursor-pointer'
              onClick={() => navigate('/Ourwork')}
            >
              View Gallery
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurArtist;
