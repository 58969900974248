import React from 'react'
import { useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();

  return (
    <div className='bg-[#4FF8A7]  xl:p-[3vh] md:p-[2vh] p-[3vh]  md:flex justify-between'>
        <div className='lg:w-[85%] md:w-[70%]'>
        <h1 className='lg:text-[40px] md:text-[30px] text-[20px] font-bold'>Are you ready to get a tattoo?</h1>
        <p className='md:text-[20px] text-[10px] font-normal 2xl:w-[72%] xl:w-[90%]'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomized.</p>
    </div>
    <div className='lg:w-[15%] md:w-[20%] flex md:justify-center md:items-center justify-end items-end md:mt-0 mt-2'>
    <button className='bg-[#000000] text-white md:h-[48px] h-[26.17px] lg:w-[149px] md:w-[155px] w-[94.33px] md:text-[16px] text-[8.72px] font-medium' onClick={()=>navigate('/Contact')}>Contact Us</button>

    </div>
    </div>
  )
}

export default Contact