import React from 'react'
import Header from '../header/Header'
import Aboutus from './Aboutus'
import Details from '../homepage/details/Details'
import Contact from '../contactus/Contact'

function AboutMainPage() {
  return (
    <div>
      <Header />
      <div className='bg-banner2 custom-bg-height 2xl:h-[370px] xl:h-[320px] md:h-[200px]  sm:h-[300px] flex justify-center items-center'>
        <h1 className='adderley text-white md:text-[80px]  text-[50px] font-bold py-[-50px]'>ABOUT US</h1>
      </div>
      <div className="aboutus w-[100%] bg-[#0F0F0F]">
        <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[2vh]'>
          <Aboutus />
        </div>
      </div>

      <div className='bg-[#000000] w-full'>
        <div className='max-w-screen-xl mx-auto xl:px-16 px-[2vh] py-8 2xl:py-16'>
          <div className='border-t border-[#FFFFFF]'></div>
          <h1 className='adderley font-bold lg:text-5xl text-3xl md:text-4xl text-white mt-4 pt-2'>
            WHY WE ARE BETTER THAN OTHERS
          </h1>

          {/* Add gap and responsive grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-7">

            {/* Card 1 */}
            <div className="bg-[#0f0f0f] p-6 rounded-lg shadow-lg">
              <div className="card-body">
                <h2 className='adderley text-white text-2xl md:text-3xl font-bold tracking-tight'>
                  EXPERT INKSTER
                </h2>
                <p className='Outfit text-white text-base md:text-lg font-normal mb-3 text-justify'>
                  Step into our studio, where your dreams become the canvas. Our expert Inkster listens intently, crafting a clear vision together, ensuring every detail of your tattoo design is perfectly understood and realized.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-[#0f0f0f] p-6 rounded-lg shadow-lg">
              <div className="card-body">
                <h2 className='adderley text-white text-2xl md:text-3xl font-bold tracking-tight'>
                  CLEAN PRACTICES
                </h2>
                <p className='text-white text-base md:text-lg font-normal mb-3 text-justify'>
                  Watch as your idea transforms into a stunning sketch, capturing your essence perfectly. Review the design with our skilled artist, providing feedback and tweaking until it embodies your imagination fully.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-[#0f0f0f] p-6 rounded-lg shadow-lg">
              <div className="card-body">
                <h2 className='adderley text-white text-2xl md:text-3xl font-bold tracking-tight'>
                  CUSTOMIZED ARTISTRY
                </h2>
                <p className='text-white text-base md:text-lg font-normal mb-3 text-justify'>
                  With your design refined, the tattooing begins. Feel the steady rhythm of the needle as your vision comes to life, transforming into a unique piece of art permanently etched onto your skin.
                </p>
              </div>
            </div>

          </div>
        </div>

        <div className="bg-[#0f0f0f]">
          <Details />
        </div>

        <div className='w-[100%] bg-[#000000]'>
          <div className='bg-[#000000] 2xl:py-[10vh] py-[5vh] max-w-1440 lg:w-[100%] mx-auto xl:px-[8vh] px-[3vh]'>
            <Contact />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMainPage