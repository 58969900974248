import React from 'react';
import Header from '../header/Header';
import { tattooData } from '../../utils/Data';
import Contact from '../contactus/Contact';
import { useNavigate } from "react-router-dom";

function FlashTattoo() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className='bg-banner2 custom-bg-height 2xl:h-[370px] xl:h-[320px] md:h-[200px]  sm:h-[300px] flex justify-center items-center'>
        <h1 className='adderley text-white md:text-[80px] text-[50px] font-bold'>
          flash tattoo
        </h1>
      </div>
      <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[2vh] 2xl:py-[10vh] py-[5vh]'>
        <div className='border-[1px] border-t-[#FFFFFF]'></div>
        <h1 className='adderley font-bold lg:text-[50px] md:text-[40px] text-[20px] text-white mt-4'>
          THINKING OF A NEW TATTOO OR PLANNING TO GET YOUR NEXT ONE.
        </h1>
        <div className="grid md:grid-cols-3 md:gap-9 gap-8 md:gap-2 mt-7">
          {tattooData.map((tattoo) => (
            <div key={tattoo.id} className="col-span-1 p-7 bg-[#000000]">
              <div className='flex justify-between'>
                <h1 className='lg:text-[24px] md:text-[20px] text-[21.94px] text-white font-normal'>
                  {tattoo.title}
                </h1>
                <p className='text-[21.94px] text-[#4FF8A7] block md:hidden'>
                  {tattoo.price}
                </p>
              </div>
              <img src={tattoo.image} alt={tattoo.title} className='w-full mt-3' />
              <div className='flex justify-center items-center mt-4'>
                <button className='bg-[#4FF8A7] h-[40px] w-[124px] text-[13.33px]' onClick={()=>navigate('/Contact')}>
                  Contact Us
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='w-[100%] bg-[#000000]'>
        <div className='bg-[#000000] 2xl:py-[10vh] py-[5vh] max-w-1440 lg:w-[100%] mx-auto xl:px-[8vh] px-[3vh]'>
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default FlashTattoo;
