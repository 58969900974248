import React from 'react'
import Header from '../header/Header'
import Services from './Services'
import OurArtist from '../ourartist/OurArtist'
import OurWorkGallery from '../ourwork/OurWorkGallery'
import Contact from '../contactus/Contact'

function ServicesMainPage() {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner Section */}
      <div className='bg-banner2 custom-bg-height 2xl:h-[370px] xl:h-[320px] md:h-[200px] h-[500]px flex justify-center items-center '>
        <h1 className='adderley text-white md:text-[80px]  text-[50px] font-bold py-[-50px]'>SERVICES</h1>
      </div>

      {/* Services Section */}
      <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[2vh] xl:py-10'>
        <Services />
      </div>

      {/* Our Artist Section */}
      <div className="w-full bg-[#000000]">
        <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[2vh] xl:py-16'>
          <OurArtist />
        </div>
      </div>
    </div>
  )
}

export default ServicesMainPage
