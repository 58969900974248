import React from 'react'
import Header from '../header/Header'
import { latesttattoos } from '../../utils/Data'
import Contact from '../contactus/Contact'
function OurWork() {
  return (
    <div>
      <Header />
      <div className='bg-banner2 custom-bg-height 2xl:h-[370px] xl:h-[320px] md:h-[200px]  sm:h-[300px] flex justify-center items-center'>
        <h1 className='adderley text-white md:text-[80px]  text-[50px] font-bold py-[-50px]'>OUR WORK</h1>
      </div>
      <div className='2xl:py-[10vh] py-[7vh] max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[2vh]'>
        <div className='border-[1px] border-t-[#FFFFFF]'></div>
        <h1 className='adderley  font-bold lg:text-[50px] md:text-[35px] text-[20px] text-white mt-6'>CHECK SOME OF OUR LATEST TATTOOS</h1>

        <div className="grid lg:grid-cols-4 grid-cols-2 gap-8 mt-7 ">
          {latesttattoos.map((tattoo) => (
            <div key={tattoo.id} className="col-span-1">
              <img src={tattoo.image} alt={tattoo.title} className='w-full mt-3' />
            </div>
          ))}
        </div>
      </div>
      <div className='w-[100%] bg-[#000000]'>
        <div className=' bg-[#000000] 2xl:py-[10vh] py-[5vh] max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[2vh]'>
          <Contact />
        </div>
      </div>
    </div>
  )
}

export default OurWork