import React, { useState } from 'react'
import TOPARROW from '../assets/images/arrow2.png'
function TopScroll() {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      {isVisible && (
        <div className="scrollToTopButton flex justify-center items-center" onClick={scrollToTop}>
{/* <img src={TOPARROW} alt='toparrow' className='w-[20px]'/> */}
<i class="fa fa-angle-up " style={{fontSize:'30px'}}></i>

        </div>
      )}
    </div>
  )
}

export default TopScroll