import React, { useState } from 'react';
import ABOUT1 from '../../assets/images/home/about/1.jpg';
import ABOUT2 from '../../assets/images/home/about/2.jpg';
import ABOUT3 from '../../assets/images/home/about/3.jpg';

function Aboutus() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='2xl:pt-[10vh] pt-[5vh] 2xl:pb-[7vh] pb-[2vh] relative'>
      <div className='border-[1px] border-t-[#FFFFFF]'></div>
      <div className='flex justify-between'>
        <h1 className='adderley lg:text-[50px] md:text-[40px] text-[20px] font-bold text-white mt-4'>ABOUT US</h1>
        <div>
          <h2 className='adderley text-[#4FF8A7] xl:text-[100px] lg:text-[70px] md:text-[50px] text-[30px] font-bold lg:mt-0 mt-3'>
            ROBIN'S TATTOO
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6 md:py-9 py-3 relative">
        <div className="col-span-1">
          <img src={ABOUT1} alt='ABOUT1' className='w-full' />
        </div>
        <div className="col-span-1">
          <img src={ABOUT2} alt='ABOUT2' className='w-full' />
        </div>
        <div className="col-span-1 relative">
          <h2 className='adderley absolute 2xl:bottom-[84%] xl:bottom-[82%] bottom-[84%] right-0 text-white xl:text-[100px] lg:text-[70px] md:text-[50px] text-[30px] font-bold text-end'>
            STUDIO
          </h2>
          <img src={ABOUT3} alt='ABOUT3' className='w-full' />
        </div>
      </div>

      {/* Default paragraph visible on all screen sizes */}
      <div>
        <p className='text-white md:text-[22.5px] text-[15px] font-light text-justify pb-5'>
          Robin's Tattoo Studio is a journey that began in 2006 when tattooing was still a budding art in Gujarat. Our founder, Robin, honed his skills with the city's best tattoo artists. By 2008, his passion led him to open his own studio, where a talented team joined him, creating a hub for exceptional tattoo artistry.
        </p>
      </div>

      {/* Content that is initially hidden on mobile devices */}
      <div className={`sm:block ${isExpanded ? 'block' : 'hidden'}`}>
        <p className='text-white md:text-[22.5px] text-[15px] font-light text-justify pb-5'>
          In 2017, I, Krunal Goswami, partnered with Robin, bringing fresh perspectives to the studio. Today, I carry forward this legacy and have established it as one of the best tattoo studios in Ahmedabad, with a professional tattoo artist. Our commitment remains unchanged: to transform your ideas into stunning, personalized tattoos that tell your unique story.
        </p>
        <p className='text-white md:text-[22.5px] text-[15px] font-light text-justify'>
          We believe that tattoos express what words often cannot. That's why we focus on capturing your story, expression, beliefs, and essence to ink down your story beautifully. With a blend of experience and creativity, we're dedicated to making your tattoo dreams a reality.
        </p>
      </div>

      {/* Expand/Collapse Button only visible on mobile devices */}
      <div className='flex justify-center items-center mt-6 sm:hidden'>
        <div className='pl-[0px] sm:pl-[20px]'>
          <button
            onClick={toggleExpand}
            style={{
              backgroundColor: '#000000',
              color: '#ffffff',
              padding: '0.70rem 0.75rem',
              borderRadius: '9999px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              alignItems: 'center',
              fontWeight: '600',
              cursor: 'pointer',
            }}
            className="hover:bg-gray-800 focus:outline-none focus:ring focus:ring-opacity-75"
          >
            <svg
              style={{ height: '24px', width: '24px', color: '#4FF8A7', alignItems: 'center', transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
