import React, { useState } from 'react';
import { servicesData } from '../../utils/Data';

function Services() {
    const [selectedService, setSelectedService] = useState(servicesData[0].name);
    const selectedServiceData = servicesData.find(service => service.name === selectedService);

    return (

        <div className='xl:py-[7vh] py-[2vh] mt-0'>
            <div className='border-[1px] border-t-[#FFFFFF]'></div>
            <h1 className='adderley md:text-[50px] text-[20px] font-bold text-white mt-2'>SERVICES</h1>
            <div className="lg:grid lg:grid-cols-6 gap-9 py-9">
                <div className="col-span-1">
                    {servicesData.map(service => (
                        <div
                            key={service.name}
                            className={`py-[20px] flex items-center justify-center ${selectedService === service.name ? 'bg-[#4FF8A7] text-black font-bold' : 'bg-[#000000] text-white font-medium'} md:mb-4 mb-2`}
                        >
                            <button
                                className='text-[16px]'
                                onClick={() => setSelectedService(service.name)}
                            >
                                {service.name}
                            </button>
                        </div>
                    ))}
                    <div className='flex justify-center items-center pl-[5px] md:pl-[5px] sm:pl-[20px] pl-[0px]'>
                        <button
                            style={{
                                backgroundColor: '#000000',
                                color: '#ffffff',
                                padding: '0.70rem 0.75rem',
                                borderRadius: '9999px',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: '600',
                                cursor: 'pointer',
                            }}
                            className="hover:bg-gray-800 focus:outline-none focus:ring focus:ring-opacity-75">
                            <svg
                                style={{ height: '24px', width: '24px', color: '#4FF8A7', alignItems: 'center' }}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </button>
                    </div>



                </div>

                <div className="div2 col-span-2 lg:order-3 order-1">
                    {selectedServiceData && (
                        <div>
                            <img
                                src={selectedServiceData.image}
                                alt={selectedServiceData.name}
                                className='w-full h-full'
                            />
                        </div>
                    )}
                </div>
                <div className="col-span-3 lg:order-2 order-2 lg:mt-0 mt-4 flex items-start">
                    <div className="flex-1">
                        {selectedServiceData && (
                            <div >
                                {selectedServiceData.description.map((text, index) => (
                                    <p key={index} className='text-white text-[20px] text-justify mb-2'>{text}</p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Services;
