import React from 'react'
import GALLERY_IMG1 from '../../assets/images/our_work/img/tattoo/img_0008_IMG_8160.jpg'
import GALLERY_IMG2 from '../../assets/images/our_work/img/tattoo/img_0011_IMG_7939.jpg'
import GALLERY_IMG3 from '../../assets/images/our_work/img/tattoo/img_0013_IMG_7731.jpg'
import GALLERY_IMG4 from '../../assets/images/our_work/img/tattoo/img_0019_IMG_6944.jpg'
import GALLERY_IMG5 from '../../assets/images/our_work/img/tattoo/img_0101_IMG_1906.jpg'
import GALLERY_IMG6 from '../../assets/images/our_work/img/tattoo/img_0112_IMG_1544.jpg'
import GALLERY_IMG7 from '../../assets/images/our_work/img/tattoo/img_0115_IMG_1054.jpg'
import GALLERY_IMG8 from '../../assets/images/our_work/img/tattoo/img6.jpg'

function OurWorkGallery() {
    return (
        <div className='2xl:py-[3vh]'>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-9 mt-7  pb-[50px]">
                <div className="col-span-1">
                    <img src={GALLERY_IMG1} alt='GALLERY_IMG1' className='w-full'/>
                </div>
                <div className="col-span-1">
                <img src={GALLERY_IMG2} alt='GALLERY_IMG1' className='w-full'/>
                </div>
                <div className="col-span-1">
                <img src={GALLERY_IMG3} alt='GALLERY_IMG1' className='w-full'/>
                </div>
                <div className="col-span-1">
                <img src={GALLERY_IMG4} alt='GALLERY_IMG1' className='w-full'/>
                </div>
                <div className="col-span-1">
                <img src={GALLERY_IMG5} alt='GALLERY_IMG1' className='w-full'/>
                </div> <div className="col-span-1">
                <img src={GALLERY_IMG6} alt='GALLERY_IMG1' className='w-full'/>
                </div> <div className="col-span-1">
                <img src={GALLERY_IMG7} alt='GALLERY_IMG1' className='w-full'/>
                </div> <div className="col-span-1">
                <img src={GALLERY_IMG8} alt='GALLERY_IMG1' className='w-full'/>
                </div>
            </div>
        </div>
    )
}

export default OurWorkGallery