// import React,{useState} from 'react';
// import { useNavigate, useLocation } from "react-router-dom";
// import LOGO from '../../assets/images/home/logo.png';
// import TOGGLE from '../../assets/images/toggle.png';

// function Header() {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [isMenuOpen, setIsMenuOpen] = useState(false);

//     const toggleMenu = () => {
//         setIsMenuOpen(!isMenuOpen);
//     };

//     const isHomePage = location.pathname === '/';
//     const isContactPage = location.pathname === '/Contact';
//     const isServices = location.pathname === '/Service';
//     const isFlashTattoo = location.pathname === '/Flash_tattoos';
//     const isOurWork = location.pathname === '/Ourwork';
//     const isOurBlogs = location.pathname === '/Blog';
//     const isAbout = location.pathname === '/About';

//     return (
//         <div className='md:pt-3 max-w-1440 w-full mx-auto xl:px-[12vh] md:px-[2vh] px-[3vh] flex items-center justify-center pr-6'>
//             <div className="overflow-hidden w-full">
//                 <nav className="flex flex-wrap items-center justify-between w-full text-lg text-gray-700">
//                     <div className="flex items-center justify-between w-full md:w-auto">
//                         <img
//                             src={TOGGLE}
//                             alt='toggle'
//                             className="h-5 w-5 cursor-pointer md:hidden block mt-[3vh]"
//                             onClick={toggleMenu}
//                         />
                        
//                         <a onClick={() => navigate("/")} className="flex justify-center w-full md:w-auto">
//                             <img
//                                 src={LOGO}
//                                 alt='logo'
//                                 className="lg:w-[110.16px] md:w-[48px] w-[48.16px] lg:h-[82.62px] md:h-[48px] h-[36.12px]"
//                             />
//                         </a>
//                     </div>

//                     <div className={`w-full md:flex md:items-center md:w-auto ${isMenuOpen ? "block bg-black pl-4" : "hidden"}`} id="menu">
//                         <ul className="pt-4 text-base text-gray-700 md:flex md:justify-between md:pt-0 justify-center items-center xl:gap-4">
//                             <li className='cursor-pointer' onClick={() => navigate("/")}>
//                                 <a className={`lg:text-[20px] md:text-[15px] font-normal text-[12px] md:p-4 py-2 block ${isHomePage ? 'text-[#4FF8A7]' : 'text-white'} hover:text-[#4FF8A7]`}>Home</a>
//                             </li>
//                             <li className='cursor-pointer' onClick={() => navigate("/About")}>
//                                 <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] ${isAbout ? 'text-[#4FF8A7]' : 'text-white'} font-normal hover:text-[#4FF8A7]`}>About</a>
//                             </li>
//                             <li className='cursor-pointer' onClick={() => navigate("/Service")}>
//                                 <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] font-medium ${isServices ? 'text-[#4FF8A7]' : 'text-white'} hover:text-[#4FF8A7]`}>Services</a>
//                             </li>
//                             <li className='cursor-pointer' onClick={() => navigate("/Flash_tattoos")}>
//                                 <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] ${isFlashTattoo ? 'text-[#4FF8A7]' : 'text-white'} font-medium hover:text-[#4FF8A7]`}>Flash Tattoo</a>
//                             </li>
//                             <li className='cursor-pointer' onClick={() => navigate("/Ourwork")}>
//                                 <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] ${isOurWork ? 'text-[#4FF8A7]' : 'text-white'} font-medium hover:text-[#4FF8A7]`}>Our Work</a>
//                             </li>
//                             <li className='cursor-pointer' onClick={() => navigate("/Blog")}>
//                                 <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] font-medium ${isOurBlogs ? 'text-[#4FF8A7]' : 'text-white'} hover:text-[#4FF8A7]`}>Blog</a>
//                             </li>
//                             <li>
//                                 <div onClick={() => navigate("/Contact")}>
//                                     <button className={`block lg:text-[20px] md:text-[14px] text-[11px] border-[1.5px] hover:border-[#4FF8A7] hover:text-[#4FF8A7] lg:w-[149px] md:w-[120px] w-[90px] lg:h-[40px] h-[34px] ${isContactPage ? 'text-gradient border-[#4FF8A7] text-[#4FF8A7]' : 'text-white border-white'}`}>
//                                         Contact Us
//                                     </button>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </nav>
//             </div>
//         </div>
//     );
// }

// export default Header;

import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import LOGO from '../../assets/images/home/logo.png';
import TOGGLE from '../../assets/images/toggle.png';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
const [ isMenuOpen, setIsMenuOpen] = useState()
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle the menu state
    };

    // Checking which page is active
    const isHomePage = location.pathname === '/';
    const isContactPage = location.pathname === '/Contact';
    const isServices = location.pathname === '/Service';
    const isFlashTattoo = location.pathname === '/Flash_tattoos';
    const isOurWork = location.pathname === '/Ourwork';
    const isOurBlogs = location.pathname === '/Blog';
    const isAbout = location.pathname === '/About';

    return (
        <div className='md:pt-3 max-w-1440 w-full mx-auto xl:px-[12vh] md:px-[2vh] px-[3vh] flex items-center justify-center pr-6'>
            <div className="overflow-hidden w-full">
                <nav className="flex flex-wrap items-center justify-between w-full text-lg text-gray-700">
                    <div className="flex items-center justify-between w-full md:w-auto">
                        <img
                            src={TOGGLE}
                            alt='toggle'
                            className="h-5 w-5 cursor-pointer md:hidden block mt-[3vh]"
                            onClick={toggleMenu}
                        />
                        
                        <a onClick={() => navigate("/")} className="flex justify-center w-full md:w-auto">
                            <img
                                src={LOGO}
                                alt='logo'
                                className="lg:w-[110.16px] md:w-[48px] w-[48.16px] lg:h-[82.62px] md:h-[48px] h-[36.12px] md:mt-0 mt-4"
                            />
                        </a>
                    </div>

                    <div className={`w-full md:flex md:items-center md:w-auto ${isMenuOpen ? "block bg-black pl-4 z-50" : "hidden"}`} id="menu">
                        <ul className="pt-4 text-base text-gray-700 md:flex md:justify-between md:pt-0 justify-center items-center xl:gap-4">
                            <li className='cursor-pointer' onClick={() => navigate("/")}>
                                <a className={`lg:text-[20px] md:text-[15px] font-normal text-[12px] md:p-4 py-2 block ${isHomePage ? 'text-[#4FF8A7]' : 'text-white'} hover:text-[#4FF8A7]`}>Home</a>
                            </li>
                            <li className='cursor-pointer' onClick={() => navigate("/About")}>
                                <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] ${isAbout ? 'text-[#4FF8A7]' : 'text-white'} font-normal hover:text-[#4FF8A7]`}>About</a>
                            </li>
                            <li className='cursor-pointer' onClick={() => navigate("/Service")}>
                                <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] font-medium ${isServices ? 'text-[#4FF8A7]' : 'text-white'} hover:text-[#4FF8A7]`}>Services</a>
                            </li>
                            <li className='cursor-pointer' onClick={() => navigate("/Flash_tattoos")}>
                                <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] ${isFlashTattoo ? 'text-[#4FF8A7]' : 'text-white'} font-medium hover:text-[#4FF8A7]`}>Flash Tattoo</a>
                            </li>
                            <li className='cursor-pointer' onClick={() => navigate("/Ourwork")}>
                                <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] ${isOurWork ? 'text-[#4FF8A7]' : 'text-white'} font-medium hover:text-[#4FF8A7]`}>Our Work</a>
                            </li>
                            {/* <li className='cursor-pointer' onClick={() => navigate("/Blog")}>
                                <a className={`md:p-4 py-2 block lg:text-[20px] md:text-[15px] text-[12px] font-medium ${isOurBlogs ? 'text-[#4FF8A7]' : 'text-white'} hover:text-[#4FF8A7]`}>Blog</a>
                            </li> */}
                            <li>
                                <div onClick={() => navigate("/Contact")}>
                                    <button className={`block lg:text-[20px] md:text-[14px] text-[11px] border-[1.5px] hover:border-[#4FF8A7] hover:text-[#4FF8A7] lg:w-[149px] md:w-[120px] w-[90px] lg:h-[40px] h-[34px] ${isContactPage ? 'text-gradient border-[#4FF8A7] text-[#4FF8A7]' : 'text-white border-white'}`}>
                                        Contact Us
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;

